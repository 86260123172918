<template>
  <div class="resizable-containers">
    <template v-for="(container, index) in containers">
      <div
        :key="`container-${container.id}`"
        :style="{
          minHeight: `${container.minHeight}px` || '',
          height: containerStyles
            ? `${containerStyles[container.id].height}px`
            : '',
        }"
        :class="`container--${container.id}`"
        class="resizable-containers__container container"
      >
        <slot :name="container.id"></slot>
      </div>
      <div
        v-if="index !== containers.length - 1"
        :key="`border-${container.id}`"
        :class="{
          'border--drag':
            topDragContainer && topDragContainer.id === container.id,
        }"
        @mousedown="onStartMove(index)"
        @touchstart="onStartMove(index)"
        class="resizable-containers__border border border--bottom"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResizableContainers',

  props: {
    containers: {
      type: Array,
      required: true,
      // default: () => [
      //   { id: 'top', minHeight: 5 },
      //   { id: 'center', minHeight: 5 },
      //   { id: 'bottom', minHeight: 5 },
      // ],
    },
  },

  data() {
    return {
      containerStyles: null,
      draggingProcess: false,
      topDragContainer: null,
      bottomDragContainer: null,
      mouse: {
        startY: null,
        currentY: null,
        diffY: null,
      },
    };
  },

  created() {},

  mounted() {
    window.addEventListener('mousemove', this.onMove);
    window.addEventListener('touchmove', this.onMove);
    window.addEventListener('mouseup', this.onEndMove);
    window.addEventListener('touchend', this.onEndMove);
    this.initContainerStyles();
  },

  beforeUnmount() {
    window.removeEventListener('mousemove', this.onMove);
    window.removeEventListener('touchmove', this.onMove);
    window.removeEventListener('mouseup', this.onEndMove);
    window.removeEventListener('touchend', this.onEndMove);
  },

  methods: {
    onStartMove(index) {
      this.topDragContainer = { ...this.containers[index], index };
      this.bottomDragContainer = { ...this.containers[index + 1], index };
      this.draggingProcess = true;
    },

    onEndMove() {
      this.draggingProcess = false;
      this.topDragContainer = null;
      this.bottomDragContainer = null;
      this.mouse = {
        startY: null,
        currentY: null,
        diffY: null,
      };
    },

    onMove(event) {
      if (!this.draggingProcess) return;

      if (event.touches && event.touches.length >= 0) {
        this.$set(this.mouse, 'currentY', event.touches[0].clientY);
      } else {
        this.$set(this.mouse, 'currentY', event.clientY);
      }

      if (!this.mouse.startY) {
        this.$set(this.mouse, 'startY', this.mouse.currentY);
      }

      const oldDiffY = this.mouse.diffY || 0;
      const newDiffY = this.mouse.startY - this.mouse.currentY;
      this.$set(this.mouse, 'diffY', newDiffY);

      this.resizeContainers(oldDiffY - newDiffY);
    },

    initContainerStyles() {
      const containerNodes = this.$el.querySelectorAll('.container');
      const styles = {};

      for (let i = 0; i < this.containers.length; i++) {
        const currentContainer = this.containers[i];
        const currentContainerNode = containerNodes[i];

        styles[currentContainer.id] = {
          height: currentContainerNode.clientHeight,
        };
      }

      this.containerStyles = styles;
    },

    resizeContainers(diff) {
      if (!diff) return;

      const topDragContainerStyles =
        this.containerStyles[this.topDragContainer.id];
      const bottomDragContainerStyles =
        this.containerStyles[this.bottomDragContainer.id];

      const newTopContainerHeight = topDragContainerStyles.height + diff;
      const newBottomContainerHeight = bottomDragContainerStyles.height - diff;

      if (diff < 0 && this.topDragContainer.minHeight > newTopContainerHeight) {
        for (let i = this.topDragContainer.index - 1; i >= 0; i--) {
          const nextTaperedContainer = this.containers[i];
          const nextDragContainerStyles =
            this.containerStyles[nextTaperedContainer.id];
          const newNextContainerHeight = nextDragContainerStyles.height + diff;

          if (nextTaperedContainer.minHeight < newNextContainerHeight) {
            this.$set(
              this.containerStyles[nextTaperedContainer.id],
              'height',
              newNextContainerHeight
            );
            this.$set(
              this.containerStyles[this.bottomDragContainer.id],
              'height',
              newBottomContainerHeight
            );
            return;
          }
        }

        return;
      } else if (
        diff > 0 &&
        this.bottomDragContainer.minHeight > newBottomContainerHeight
      ) {
        for (
          let i = this.bottomDragContainer.index + 1;
          i < this.containers.length;
          i++
        ) {
          const nextTaperedContainer = this.containers[i];
          const nextDragContainerStyles =
            this.containerStyles[nextTaperedContainer.id];
          const newNextContainerHeight = nextDragContainerStyles.height - diff;

          if (nextTaperedContainer.minHeight < newNextContainerHeight) {
            this.$set(
              this.containerStyles[this.topDragContainer.id],
              'height',
              newTopContainerHeight
            );
            this.$set(
              this.containerStyles[nextTaperedContainer.id],
              'height',
              newNextContainerHeight
            );
            return;
          }
        }

        return;
      }

      this.$set(
        this.containerStyles[this.topDragContainer.id],
        'height',
        newTopContainerHeight
      );
      this.$set(
        this.containerStyles[this.bottomDragContainer.id],
        'height',
        newBottomContainerHeight
      );
    },
  },
};
</script>

<style lang="scss">
.resizable-containers {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  & .container {
    flex-grow: 1;
    user-select: none;
    overflow: hidden;
  }

  & .border {
    border-radius: 5px;
    background-color: var(--gray);
    user-select: none;
    cursor: s-resize;
    transition: all 0.2s;

    &:hover,
    &--drag {
      background-color: var(--light-gray);
    }
  }
}

.resizable-containers__border {
  min-height: 5px;
}
</style>
