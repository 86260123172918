var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resizable-containers"},[_vm._l((_vm.containers),function(container,index){return [_c('div',{key:("container-" + (container.id)),staticClass:"resizable-containers__container container",class:("container--" + (container.id)),style:({
        minHeight: ((container.minHeight) + "px") || '',
        height: _vm.containerStyles
          ? ((_vm.containerStyles[container.id].height) + "px")
          : '',
      })},[_vm._t(container.id)],2),(index !== _vm.containers.length - 1)?_c('div',{key:("border-" + (container.id)),staticClass:"resizable-containers__border border border--bottom",class:{
        'border--drag':
          _vm.topDragContainer && _vm.topDragContainer.id === container.id,
      },on:{"mousedown":function($event){return _vm.onStartMove(index)},"touchstart":function($event){return _vm.onStartMove(index)}}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }